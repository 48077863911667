import store from '../store'

let baseURLHttp = 'https://jjzxapp.com' 
let wsurl = 'wss://jjzxapp.com/websocket/wsMessage' //域名
//  let baseURLHttp = 'https://dev.jjzxapp.com' //域名
//  let wsurl = 'wss://dev.jjzxapp.com/websocket/wsMessage' //域名


const hostname = location.hostname; 
if(hostname && hostname == 'localhost') {
} else {
	if(location.origin) {
		baseURLHttp = location.origin
		wsurl = (location.protocol == 'https:'?'wss://':'ws://') + location.hostname + '/websocket/wsMessage'
	}
}

export default {
	baseURLHttp,
	wsurl
}

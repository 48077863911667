import axios from 'axios'
import baseURL from './config.js'
import storage from "good-storage";
// 封装请求 及 配置
const host = baseURL.baseURLHttp
axios.defaults.baseURL = host + "/oew/api"
export const fetch = (url, options) => {
		/* axios.defaults.headers = {
			"Content-Type": "application/json",
			"Accept": "application/json"
		} */
		//debugger
		const api_token = storage.get("lemon_Authorization") || "";
		//console.log('api_token:'+api_token)
		if (options.data.noToken) {
			axios.defaults.headers = {
			  "Content-Type": "application/json",
			  Accept: "application/json",
			};
		}	
		else if (api_token) {
		  axios.defaults.headers = {
		    "Content-Type": "application/json",
		    Accept: "application/json",
		    Authorization: api_token,
		  };
		} else {
		  axios.defaults.headers = {
		    "Content-Type": "application/json",
		    Accept: "application/json",
		  };
		}
	return new Promise((resolve, reject) => {
		axios(url, options)
			.then(response => {
				 if (response.status == 200) {
					resolve(response.data)
				} else {
					reject(response.data)
				}
			}, err => {
				console.log(err)
				reject(err)
			})
			.catch((error) => {
				console.log(error)
				reject(error)
			})
	})
}

import {
	fetch
} from "./index"; //引用fetch.js


export function checkWsToken(params) {
	return fetch(`/osmdb/wsRest/checkWsToken`, {
		method: 'post',
		data: params
	})
}

//密码登录
export function upwLogin(params) {
	return fetch(`/upwLogin`, {
		method: 'post',
		data: params,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	})
}
//验证码登录
export function smsLogin(params) {
	return fetch(`/smsLogin`, {
		method: 'post',
		data: params,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	})
}
//发送登录验证码
export function getSmsCode(params) {
	return fetch(`/getSmsCode`, {
		method: 'post',
		data: params
	})
}
//注销
export function logout(params) {
	return fetch(`/logout`, {
		method: 'post',
		data: params
	})
}
//获取机构
export function queryPmuserOwnOrgan(params) {
	return fetch(`/pmdb/pmuserOrganRest/queryPmuserOwnOrgan`, {
		method: 'post',
		data: params
	})
}
//登录机构
export function addPmuserUseOrgan(params) {
	return fetch(`/pmdb/pmuserRest/addPmuserUseOrgan`, {
		method: 'post',
		data: params
	})
}

export function addUserUseOrgan(params) {
	return fetch(`/osmdb/userRest/addUserUseOrgan`, {
		method: 'post',
		data: params
	})
}

export function queryAndAddOwnInitUserQuestionBanks(params) {
	return fetch(`/qbms/userQuestionBankRest/queryAndAddOwnInitUserQuestionBanks`, {
		method: 'post',
		data: params
	})
}

//获取机构
export function queryUserOwnOrgan(params) {
  return fetch(`/osmdb/userOrganRest/queryUserOwnOrgan`, {
    method: "post",
    data: params,
  });
}

//侧边栏 查询一本教辅有哪些页面报错了 哪些报错处理了
export function queryEffectiveImageVideos(params) {
	return fetch(`/osmdb/imageVideoRest/queryEffectiveImageVideos`, {
		method: 'post',
		data: params,
	})
}
//协议页面 查询用户协议和用户隐私协议
export function queryOneAppAgreement(params) {
	return fetch(`/osmdb/appRest/queryOneAppAgreement`, {
		method: 'post',
		data: params,
	})
}

//获取图形验证码
export function getOnePtv(params) {
  return fetch(`/osmdb/ptvRest/getOnePtv`, {
    method: "post",
    data: params,
  });
}
//查询验证结果--图形验证码
export function checkPtvV2(params) {
  return fetch(`/osmdb/ptvRest/checkPtvV2`, {
    method: "post",
    data: params,
  });
}

//发送验证码前查看手机号是否已经登陆过多次了
export function isSmsCaptcha(params) {
  return fetch(`/isSmsCaptcha`, {
    method: "post",
    data: params,
  });
}
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const routes = [
	
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')

  },
  
  {
    path: '/TestWord',
    name: 'TestWord',
    component: () => import(/* webpackChunkName: "about" */ '../views/Test.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import(/* webpackChunkName: "about" */ '../views/Help.vue')
  },
  {
    path: '/pdfUrl',
    name: 'PdfUrl',
    component: () => import(/* webpackChunkName: "about" */ '../views/PdfUrl.vue')
  },
  {
    path: '/join_us',
    name: 'JoinUs',
    component: () => import(/* webpackChunkName: "We" */ '../views/JoinUs.vue')
  } , 
  {
    path: '/agreement',
    name: 'Agreement',
    component: () => import(/* webpackChunkName: "We" */ '../views/Agreement.vue')
  } , 
  {
    path: '/download',
    name: 'DownLoad',
    component: () => import(/* webpackChunkName: "We" */ '../views/DownLoad.vue')
  } ,{
    path: '/functionHome',
    name: 'FunctionHome',
    component: () => import(/* webpackChunkName: "We" */ '../views/function/Home.vue')
  } ,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(_vm.show_topFooter)?_c('div',{attrs:{"id":"nav"}},[_c('a',{staticClass:"logo",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.RouteTo('/TestWord')}}},[_c('img',{staticClass:"logo-img",attrs:{"src":require("@/assets/img/logo202109021.png"),"alt":"logo"}})]),_c('div',{staticClass:"top-link"},[_c('a',{staticClass:"top-link-text cant-select g-color",class:{
            'top-link-text-normal': _vm.current_route !== 'Home',
            'top-link-text-active': _vm.current_route == 'Home',
          },attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.RouteTo('/')}}},[_vm._v("精教产品")]),_c('a',{staticClass:"top-link-text cant-select g-color ",class:{
		    'top-link-text-normal': _vm.current_route !== '1',
		    'top-link-text-active': _vm.current_route == '1',
		  },attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.RouteTo('/functionHome')}}},[_vm._v("功能中心")]),_c('a',{staticClass:"top-link-text cant-select",class:{
            'top-link-text-normal': _vm.current_route !== 'Help',
            'top-link-text-active': _vm.current_route == 'Help',
          },attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.RouteTo('/help')}}},[_vm._v("帮助中心")]),_c('a',{staticClass:"top-link-text cant-select",class:{
            'top-link-text-normal': _vm.current_route !== 'About',
            'top-link-text-active': _vm.current_route == 'About',
          },attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.RouteTo('/about')}}},[_vm._v("关于我们")]),_c('a',{staticClass:"top-link-text cant-select",class:{
            'top-link-text-normal': _vm.current_route !== 'JoinUs',
            'top-link-text-active': _vm.current_route == 'JoinUs',
          },attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.RouteTo('/join_us')}}},[_vm._v("加入我们")])])]):_vm._e(),(_vm.show_topFooter)?_c('div',{class:{ MobileNav_active: _vm.MobileNav, MobileNav_normal: !_vm.MobileNav },attrs:{"id":"MobileNav"},on:{"click":function($event){$event.stopPropagation();_vm.MobileNav ? (_vm.MobileNav = false) : (_vm.MobileNav = false)}}},[_c('div',{staticClass:"MobileNav-box"},[_c('img',{staticClass:"logo-img-m",attrs:{"src":require("@/assets/img/logo-m202109021.png"),"alt":"logo"}}),_c('span',{staticStyle:{"font-size":"22px"},on:{"click":function($event){$event.stopPropagation();_vm.MobileNav = !_vm.MobileNav}}},[_vm._v(" "+_vm._s(_vm.MobileNav ? "关闭" : "展开")+" ")])]),_c('div',{staticClass:"top-link",class:{
          'top-link_active': _vm.MobileNav,
          'top-link_normal': !_vm.MobileNav,
        }},[_c('a',{staticClass:"top-link-text cant-select g-color",class:{
            'top-link-text-normal': _vm.current_route !== 'Home',
            'top-link-text-active': _vm.current_route == 'Home',
          },attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.RouteTo('/')}}},[_vm._v("精教产品")]),_c('a',{staticClass:"top-link-text cant-select",class:{
            'top-link-text-normal': _vm.current_route !== 'Help',
            'top-link-text-active': _vm.current_route == 'Help',
          },attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.RouteTo('/help')}}},[_vm._v("帮助中心")]),_c('a',{staticClass:"top-link-text cant-select",class:{
            'top-link-text-normal': _vm.current_route !== 'About',
            'top-link-text-active': _vm.current_route == 'About',
          },attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.RouteTo('/about')}}},[_vm._v("关于我们")]),_c('a',{staticClass:"top-link-text cant-select",class:{
            'top-link-text-normal': _vm.current_route !== 'JoinUs',
            'top-link-text-active': _vm.current_route == 'JoinUs',
          },attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.RouteTo('/join_us')}}},[_vm._v("加入我们")])])]):_vm._e(),(_vm.show_topFooter)?_c('div',{staticClass:"nav_fake"}):_vm._e(),_c('router-view',{staticClass:"router-view"}),(_vm.show_topFooter)?_c('div',{staticClass:"bar"}):_vm._e(),(_vm.show_topFooter)?_c('div',{staticClass:"footer"},[_c('p',{staticClass:"footer-text footer-text-1",staticStyle:{"padding-bottom":"10px"}},[_c('a',{staticStyle:{"color":"#fff"},attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.new_router('/agreement', 0)}}},[_vm._v("用户服务协议｜ ")]),_c('a',{staticStyle:{"color":"#fff"},attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.new_router('/agreement', 1)}}},[_vm._v("用户隐私政策｜ ")]),_c('a',{staticStyle:{"color":"#fff"},attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.new_router('/agreement', 2)}}},[_vm._v("儿童隐私政策｜ ")]),_c('a',{staticStyle:{"color":"#fff"},attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.new_router('/agreement', 3)}}},[_vm._v("营业执照 ")])]),_vm._m(0),_c('p',{staticClass:"footer-text footer-text-2 footer-text-1024"},[_c('a',{staticClass:"footer-text-1024-1",staticStyle:{"color":"#fff"},attrs:{"href":"javascript:void(0);"},on:{"click":_vm.open_other_window}},[_vm._v("鲁ICP备2021019896号 ")]),_c('a',{staticClass:"footer-text-1024-1",staticStyle:{"color":"#fff"},attrs:{"href":"javascript:void(0);"},on:{"click":_vm.open_ga_window}},[_vm._v(" 鲁公网安备 37010402001025号 ")]),_c('a',{staticClass:"footer-text-1024-2",staticStyle:{"color":"#fff"},attrs:{"href":"javascript:void(0);"}},[_vm._v("开发者:山东精准教育科技发展有限公司")])])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"footer-text footer-text-1"},[_c('a',{staticClass:"footer-text-1024",staticStyle:{"color":"#fff"},attrs:{"href":"javascript:void(0);"}},[_c('span',{staticClass:"footer-text-1024 footer-text-1024-1"},[_vm._v("客服热线：19153108351")]),_c('span',{staticClass:"footer-text-1024 footer-text-1024-2"},[_vm._v("公司地址：山东省济南市槐荫区绿地齐鲁之门B2-b1103")])])])}]

export { render, staticRenderFns }
<template>
  <div id="app">
    <!-- PC nav -->
    <div id="nav" v-if="show_topFooter">
      <!-- logo -->
      <a href="javascript:void(0);" class="logo" @click="RouteTo('/TestWord')">
        <img src="@/assets/img/logo202109021.png" alt="logo" class="logo-img" />
        <!-- <span class="logo-text">精教智学</span> -->
      </a>
      <div class="top-link">
        <a
          href="javascript:void(0);"
          class="top-link-text cant-select g-color"
          :class="{
            'top-link-text-normal': current_route !== 'Home',
            'top-link-text-active': current_route == 'Home',
          }"
          @click="RouteTo('/')"
          >精教产品</a
        >
		<a
		  href="javascript:void(0);"
		  class="top-link-text cant-select g-color "
		  :class="{
		    'top-link-text-normal': current_route !== '1',
		    'top-link-text-active': current_route == '1',
		  }"
		  @click="RouteTo('/functionHome')"
		  >功能中心</a
		>
    <a
          href="javascript:void(0);"
          class="top-link-text cant-select"
          :class="{
            'top-link-text-normal': current_route !== 'Help',
            'top-link-text-active': current_route == 'Help',
          }"
          @click="RouteTo('/help')"
          >帮助中心</a
        >
        <a
          href="javascript:void(0);"
          class="top-link-text cant-select"
          :class="{
            'top-link-text-normal': current_route !== 'About',
            'top-link-text-active': current_route == 'About',
          }"
          @click="RouteTo('/about')"
          >关于我们</a
        >
        <a
          href="javascript:void(0);"
          class="top-link-text cant-select"
          :class="{
            'top-link-text-normal': current_route !== 'JoinUs',
            'top-link-text-active': current_route == 'JoinUs',
          }"
          @click="RouteTo('/join_us')"
          >加入我们</a
        >
      </div>
    </div>

    <!-- 移动端 nav -->
    <div
      id="MobileNav"
      v-if="show_topFooter"
      :class="{ MobileNav_active: MobileNav, MobileNav_normal: !MobileNav }"
      @click.stop="MobileNav ? (MobileNav = false) : (MobileNav = false)"
    >
      <div class="MobileNav-box">
        <img src="@/assets/img/logo-m202109021.png" alt="logo" class="logo-img-m" />
        <span @click.stop="MobileNav = !MobileNav" style="font-size: 22px">
          {{ MobileNav ? "关闭" : "展开" }}
        </span>
      </div>

      <!-- 
      
        :class="{
          '': MobileNav,
          'top-link_normal': !MobileNav,
        }"

       -->

      <div
        class="top-link"
        :class="{
          'top-link_active': MobileNav,
          'top-link_normal': !MobileNav,
        }"
      >
        <a
          href="javascript:void(0);"
          class="top-link-text cant-select g-color"
          :class="{
            'top-link-text-normal': current_route !== 'Home',
            'top-link-text-active': current_route == 'Home',
          }"
          @click="RouteTo('/')"
          >精教产品</a
        >
          <!-- <a
          href="javascript:void(0);"
          class="top-link-text cant-select"
          :class="{
            'top-link-text-normal': current_route !== 'DownLoad',
            'top-link-text-active': current_route == 'DownLoad',
          }"
          @click="RouteTo('/download')"
          >产品下载</a
        > -->
        <a
          href="javascript:void(0);"
          class="top-link-text cant-select"
          :class="{
            'top-link-text-normal': current_route !== 'Help',
            'top-link-text-active': current_route == 'Help',
          }"
          @click="RouteTo('/help')"
          >帮助中心</a
        >
        <a
          href="javascript:void(0);"
          class="top-link-text cant-select"
          :class="{
            'top-link-text-normal': current_route !== 'About',
            'top-link-text-active': current_route == 'About',
          }"
          @click="RouteTo('/about')"
          >关于我们</a
        >
        <a
          href="javascript:void(0);"
          class="top-link-text cant-select"
          :class="{
            'top-link-text-normal': current_route !== 'JoinUs',
            'top-link-text-active': current_route == 'JoinUs',
          }"
          @click="RouteTo('/join_us')"
          >加入我们</a
        >
       
      </div>
    </div>

    <div class="nav_fake" v-if="show_topFooter"></div>

    <router-view class="router-view" />
    <div class="bar" v-if="show_topFooter"></div>
    <!-- 页脚 -->
    <div class="footer" v-if="show_topFooter">
      <p class="footer-text footer-text-1" style="padding-bottom: 10px">
        <a
          href="javascript:void(0);"
          style="color: #fff"
          @click="new_router('/agreement', 0)"
          >用户服务协议｜
        </a>
        <a
          href="javascript:void(0);"
          style="color: #fff"
          @click="new_router('/agreement', 1)"
          >用户隐私政策｜
        </a>
        <a
          href="javascript:void(0);"
          style="color: #fff"
          @click="new_router('/agreement', 2)"
          >儿童隐私政策｜
        </a>
        <a
          href="javascript:void(0);"
          style="color: #fff"
          @click="new_router('/agreement', 3)"
          >营业执照
        </a>
      </p>

      <p class="footer-text footer-text-1">
        <a
          href="javascript:void(0);"
          style="color: #fff"
          class="footer-text-1024"
        >
          <span class="footer-text-1024 footer-text-1024-1"
            >客服热线：19153108351</span
          >
          <span class="footer-text-1024 footer-text-1024-2"
            >公司地址：山东省济南市槐荫区绿地齐鲁之门B2-b1103</span
          >
        </a>
      </p>

      <p class="footer-text footer-text-2 footer-text-1024">
        <a
          class="footer-text-1024-1"
          href="javascript:void(0);"
          style="color: #fff"
          @click="open_other_window"
          >鲁ICP备2021019896号
        </a>
		
		<a
		  class="footer-text-1024-1"
		  href="javascript:void(0);"
		  style="color: #fff"
		  @click="open_ga_window"
		  >  鲁公网安备 37010402001025号
		</a>
        <a
          href="javascript:void(0);"
          style="color: #fff"
          class="footer-text-1024-2"
          >开发者:山东精准教育科技发展有限公司</a
        >
      </p>
    </div>
  </div>
</template>

<script>
	import storage from "good-storage";
	import {checkWsToken} from "@/axios/login";
export default {
	components: {
		
	},
  name: "App",
  data() {
    return {
      MobileNav: false, // 移动端模式下 nav 是否已经打开
      current_route: "Home", // 当前路由
      show_topFooter: false,
    };
  },
  watch: {
    $route(to) {
      this.current_route = to.name;
      console.log(this.current_route);

      if (
        this.current_route == "Home" ||
        this.current_route == "JoinUs" ||
        this.current_route == "About"||
        this.current_route == "Help"||
        this.current_route == "DownLoad"
      ) {
        // 如果在tab页面才会有 头部、底部
        this.show_topFooter = true;
      } else {
        this.show_topFooter = false;
      }
    },
  },
  
  mounted() {
  	  console.log('app:')
	  
	   
	  if(storage.get("lemon_Authorization")){
		  checkWsToken({}).then(res => {
		  	if (res.status == 0) {
		  			this.RouteTo('/functionHome')
		  	}else{
				console.log('__token已失效')
				//storage.clear();
			}
		  })
	  }else{
		console.log('__没有token')
	  }
		//storage.set("lemon_userInfo",this.userInfo)
  },
  
  methods: {
	  
    RouteTo(name) {
		console.log('RouteTo:'+name)
      this.$router.push(name);
      this.MobileNav = false;
    },

    // new router at other window
    new_router(path, type) {
      // console.log(type) ;
      let params = {
        type,
      };
      let routeData = this.$router.resolve({
        path: `${path}`,
        query: params,
      });
      window.open(routeData.href, "_blank");
    },

    // 打开外链
    open_other_window() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    },
	
	open_ga_window() {
	  window.open("https://www.beian.gov.cn/portal/registerSystemInfo");
	},
  },
};
</script>
  

<style scoped>
	@import "./assets/css/common.css";
	
#MobileNav {
  display: none;
}
.bar {
  width: 100%;
  height: 106px;
}
.footer {
  width: 100%;
  height: 195px;
  max-height: 195px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 60px 0 50px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: #303030;


}
.footer   .footer-text-1 {
    font-size: 18px;
    display: flex;
    align-items: center;
  }
 .footer  .footer-text-2 {
    font-size: 14px;
  }
.footer   .footer-text {
    color: #fff;
    font-family: PingFangSC-Semibold;
  }
.router-view {
  padding-bottom: 90px;
  background-color: rgba(247, 248, 250, 1);
  /** min-height: calc(100vh - 106px); **/
  min-height: 100vh;
}
.top-link-text-normal {
  color: #333;
  border-color: #fff !important;
}
.top-link-text-active {
  color: #319bff;
  border-color: #319bff;
}
.top-link-text {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  margin-left: 32px;
  border-bottom: 2px solid;
  padding-bottom: 9px;
}
.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;

}
 .logo  .logo-img {
    width: 169px;
  }
  .logo .logo-text {
    height: 40px;
    font-family: PingFangSC-Regular;
    font-size: 28px;
    color: #000e18;
    font-weight: 400;
    margin-left: 15px;
  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  min-height: 100vh;
}

#nav {
  display: flex;
  width: 100%;
  height: 106px;
  background: #ffffff;
  /* border-bottom: 1px solid #979797; */
  align-items: center;
  padding: 0 107px 0 97px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
.nav_fake {
  content: "";
  width: 100%;
  height: 106px;
  display: block;
}

@media screen and (max-width: 1024px) {
  .MobileNav_normal {
    height: 70px;
  }
  .MobileNav_active {
    height: 100%;
  }
  #MobileNav {
    display: block;
    will-change: height;
    transition: all 0.5s;
    overflow: hidden;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
  }
  .top-link_normal {
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }
  .top-link_active {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .top-link {
    height: 50%;
    flex-shrink: 0;
    will-change: height;
    transition: all 0.5s;
    display: flex;
    padding: 0 15px;
    background: #ffffff;
    flex-direction: column;

    box-shadow: 0px 5px 10px 0px #999;
    justify-content: flex-start !important ;
    margin-top: 0px !important;
  }
  .MobileNav-box {
    display: flex;
    flex-shrink: 0;
    height: 70px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dddddd;
    padding: 0 15px;
    background-color: #ffffff;
  }
  .logo-img-m {
    width: 170px;
    height: 36px;
  }
  #nav {
    padding: 0 24px;
    flex-direction: column;
    justify-content: center;
    display: none;
  }
  .nav_fake {
    height: 60px;
  }
  .top-link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }
  .footer-text-1024 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-text-1024-2 {
    padding-top: 10px;
  }
  .footer-text-1024-1 {
    padding-top: 10px;
  }
  .top-link-text {
    margin-left: 0px;
    font-size: 26px;
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .footer {
    padding: 50px 10px;
    align-items: flex-start;
  }
}
</style>

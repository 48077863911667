import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import Swiper, { Pagination, Autoplay } from "swiper";
// import 'element-ui/lib/theme-chalk/index.css';
Swiper.use([Pagination, Autoplay]);

// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/css/reset.css';
import './assets/css/global.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false;
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');


